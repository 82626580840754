<template>
  <div id="login">
    <!--    <el-image-->
    <!--        :src="require('@/assets/images/login-logo.png')"-->
    <!--        fit="contain"-->
    <!--        class="login-logo"-->
    <!--    ></el-image>-->
    <div class="login_container">
      <div class="login_decorate">
        <el-image
            :src="require('@/assets/images/login_decorate.png')"
            fit="contain"
        ></el-image>
      </div>
      <div class="inp">
        <h3>欢迎登录点点行行后台系统</h3>
        <ul>
          <li>
            <span class="label">用户名</span>
            <el-input v-model="userName" placeholder="请输入手机号"></el-input>
          </li>
          <li>
            <span class="label">密码</span>
            <el-input
                v-model="password"
                type="password"
                show-password
                placeholder="请输入密码"
                @keyup.enter.native="login"
            ></el-input>
          </li>
        </ul>
        <div class="option">
          <el-checkbox v-model="isRememberPaw">记住登录密码</el-checkbox>
        </div>
        <el-button
            class="loginBtn"
            type="primary"
            @click="login"
            :loading="isLoading"
            :disabled="isLoading"
        >登录</el-button
        >
      </div>
    </div>
    <!-- 备案号 -->
    <div class="beian">
      <span
      >Copyright © 2023 新城数字
        <a href="https://beian.miit.gov.cn" target="_blank"
        >浙ICP备2021038739号-1</a
        >
      </span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userName: "",
      password: "",
      isLoading: false,
      isRememberPaw: true,
    };
  },
  created() {
    let userName = localStorage.getItem("userName");
    let password = localStorage.getItem("password");
    if (userName) {
      this.userName = userName;
      this.password = password;
    }
  },
  methods: {
    async login() {
      if (!this.userName) {
        this.$message.warning("请输入手机号");
        return;
      }
      if (!this.password) {
        this.$message.warning("请输入密码");
        return;
      }
      try {
        this.isLoading = true;
        let res = await this.$http.post("/login", {
          username: this.userName,
          password: this.password,
        });
        if (res.code === 0) {
          localStorage.setItem("userName", this.userName);
          if (this.isRememberPaw) {
            localStorage.setItem("password", this.password);
          } else {
            localStorage.removeItem("password");
          }
          sessionStorage.setItem("token", res.data.token);
          this.$store.commit("SET_TOKEN", res.data.token);
          this.$store.commit("SET_USER", {
            userName: res.data.username,
            userId: res.data.userId,
            authorities: res.data.authorities,
          });
          if (res.data.roleDetail) {
            let roleDetail = JSON.parse(res.data.roleDetail);
            sessionStorage.setItem("controlAuthority", res.data.roleDetail);
            this.$store.commit("SET_CONTROLAUTHORITY", roleDetail);
            if (roleDetail && roleDetail.pathNameList.length > 0) {
              setTimeout(() => {
                this.$store.dispatch("refreshToken");
              }, 1000 * 60 * 110);
              this.$router.replace({ name: roleDetail.pathNameList[0] });
            } else {
              this.$message.error("未配置权限,拒绝访问!");
            }
          } else {
            this.$message.error("未配置权限,拒绝访问!");
          }
        } else {
          this.$message.error(res.msg);
        }
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#login {
  width: 100%;
  height: 100vh;
  background: url("../../assets/images/login_bg.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .login-logo {
    position: fixed;
    top: 30px;
    left: 30px;
  }
  .login_container {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    .login_decorate {
      width: 540px;
    }
    .inp {
      width: 426px;
      background-color: white;
      border-radius: 7px;
      padding: 58px 27px;
      box-sizing: border-box;
      position: relative;
      h3 {
        color: rgba(26, 26, 26, 1);
        font-size: 25px;
        margin-bottom: 65px;
        text-align: center;
        font-weight: initial;
      }
      ul {
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid rgba(213, 211, 211, 1);
          padding: 17px 11px;
          margin-bottom: 38px;
          &:last-child {
            margin-bottom: 20px;
          }
          .label {
            display: inline-block;
            width: 70px;
            white-space: nowrap;
            margin-right: 30px;
            color: rgba(102, 102, 102, 1);
            font-size: 15px;
          }
          .el-input {
            .el-input__inner {
              border: none;
              height: 100%;
              padding: 0;
            }
            .el-input__suffix {
              .el-input__suffix-inner {
                .el-input__icon {
                  line-height: initial;
                }
              }
            }
          }
        }
      }
      .option {
        .el-checkbox {
          .el-checkbox__label {
            color: $main-color;
          }
        }
      }
      .loginBtn {
        position: absolute;
        bottom: 58px;
        width: calc(100% - 54px);
        height: 57px;
        font-size: 18px;
      }
    }
  }
  .beian {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    color: #fff;
  }
}
</style>
